<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>用户列表</span>
        <el-button
          type="success"
          icon="el-icon-plus"
          @click="add"
          v-if="$check(51)"
          style="float: right; position: relative; bottom: 10px"
          >添加</el-button
        >
        <el-button
          type="primary"
          @click="query(1)"
          style="float: right; margin-right: 10px; position: relative; bottom: 10px"
          icon="el-icon-search"
          >查询</el-button
        >
        <el-input
          v-model="filterData.search"
          placeholder="输入关键字搜索"
          clearable
          style="
            float: right;
            width: 250px;
            margin-right: 10px;
            position: relative;
            bottom: 10px;
          "
        ></el-input>
      </div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="firstName" label="名"></el-table-column>
        <el-table-column prop="lastName" label="张"></el-table-column>
        <el-table-column prop="title" label="称谓"></el-table-column>
        <el-table-column prop="phone" label="⼿机号"></el-table-column>
        <el-table-column prop="email" label="邮箱"></el-table-column>

        <!-- <el-table-column prop="visual1" label="类⽬⻚图⽚1">
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.visual1"
              fit="contain"
            ></el-image>
          </template>
        </el-table-column> -->

        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              v-if="$check(52)"
              size="small"
              type="primary"
              @click="edit(scope.row)"
              >编辑</el-button
            >

            <el-button
              v-if="$check(53)"
              size="small"
              type="danger"
              @click="remove(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-col :span="24" style="text-align: center; margin-top: 10px">
          <el-pagination
            background
            layout="total,prev, pager, next"
            @current-change="pageChange"
            :current-page="filterData.page"
            :total="total"
          ></el-pagination>
        </el-col>
      </el-row>
    </el-card>

    <el-dialog :title="title + '分类'" :visible.sync="dialogFormVisible">
      <el-card>
        <!-- <div slot="header">
          <span>添加分类</span>
        </div> -->
        <el-form :model="form" ref="form" label-width="120px">
          <el-form-item
            label="类⽬名称"
            prop="name"
            :rules="[{ required: true, message: '类⽬名称不能为空' }]"
          >
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="类⽬⽂案"
            prop="description"
            :rules="[{ required: true, message: '类⽬⽂案不能为空' }]"
          >
            <el-input
              type="textarea"
              :rows="3"
              v-model="form.description"
              autocomplete="off"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="排版⽅式"
            prop="layout"
            :rules="[{ required: true, message: '类⽬⻚排版⽅式不能为空' }]"
          >
            <el-input v-model="form.layout" autocomplete="off"></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="12"
              ><el-form-item
                label="图⽚1"
                prop="visual1"
                :rules="[{ required: true, message: '产品数量不能为空' }]"
              >
                <ImageUpload v-model="form.visual1"></ImageUpload> </el-form-item
            ></el-col>
            <el-col :span="12"
              ><el-form-item
                label="图⽚2"
                prop="visual2"
                :rules="[{ required: true, message: '产品数量不能为空' }]"
              >
                <ImageUpload v-model="form.visual2"></ImageUpload> </el-form-item
            ></el-col>
          </el-row>
        </el-form>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ImageUpload from "../common/ImageUpload.vue";
export default {
  components: { ImageUpload },
  data() {
    return {
      items: [],
      title: "添加",
      total: 0,
      rule: [],
      filterData: { page: 1 },
      tableData: [],
      dialogFormVisible: false,
      form: {},
    };
  },

  created() {
    this.query();
  },

  methods: {
    pageChange(page) {
      this.filterData.page = page;
      this.query();
    },
    query(page) {
      if (page) this.filterData.page = page;
      this.$http
        .post("/admin/user/list", this.filterData)
        .then((res) => {
          if (res.status == 200) {
            this.tableData = res.data.data;
            this.total = res.data.total;
          }
        })
        .catch(() => {});
    },
    remove(id) {
      this.$confirm("此操作将永久删除该条记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http.post("/admin/user/remove", { id: id }).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.query();
            }
          });
        })
        .catch(() => {});
    },

    edit(row) {
      this.title = "修改";
      this.form = row;
      this.dialogFormVisible = true;
    },

    add() {
      this.title = "添加";
      this.dialogFormVisible = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http.post("/admin/user/save", this.form).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.dialogFormVisible = false;
              this.form = {};
              this.query();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
